<template>
  <div id="print">
    <!-- 标题 -->
    <div>
      <p class="title">入库验收单</p>
    </div>

    <!-- 信息 -->
    <div style="margin-bottom: 10px;line-height: 30px;">
      <a-row :gutter="24">
        <a-col :span="12">
          客户名称：{{ orderData.agency_name }}
        </a-col>
        <a-col :span="12" style="text-align: right">
          入库单号：{{ orderData.serial_num }}
        </a-col>
      </a-row>
    </div>

    <!-- 表格 -->
    <div>
      <table>
        <thead>
          <tr>
            <th style="width: 50px;">序号</th>
            <th style="width: 180px;">进库核注清单</th>
            <th style="width: 200px;">货物信息</th>
            <th style="width: 100px;">规格型号</th>
            <th style="width: 60px;">货物数量</th>
            <th style="width: 60px;">货物单位</th>
            <th style="width: 60px;">申报数量</th>
            <th style="width: 60px;">申报单位</th>
            <th style="width: 200px;">备注</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(good, goodIdx) in goodsList" :key="goodIdx">
            <td>{{ goodIdx+1 }}</td>
            <td>{{ orderData.check_num }}</td>
            <td>{{ good.good_name }}</td>
            <td>{{ good.good_spec }}</td>
            <td>{{ good.num }}</td>
            <td>{{ good.unit }}</td>
            <td>{{ good.upload_num }}</td>
            <td>{{ good.upload_unit }}</td>
            <td>{{ good.supple_remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 底部 -->
    <p style="margin-top: 10px;line-height: 30px;">
      <a-row :gutter="24">
        <a-col :span="24"><span class="bottomStyle">入库备注：</span>{{ orderData.remark }}</a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12"><span class="bottomStyle">入库日期：</span>{{ orderData.store_date?moment(orderData.store_date).format('YYYY-MM-DD'):"" }}</a-col>
        <a-col :span="12"><span class="bottomStyle">制单员：</span>{{orderData.customer_name}}</a-col>
        <a-col :span="24"><span class="bottomStyle">仓库收货签收：</span></a-col>
      </a-row>
    </p>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: `ImportSheetBill`,
  props: {
    currentData: { type: Object, default: null },
    currentGoodsData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val;
    },
    currentGoodsData: {
      handler(val, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      goodsList: []
    }
  },
  methods: {
    moment,
    initData() {
      // 初始化数据
      this.orderData = this.currentData;
      this.goodsList = this.currentGoodsData;
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 10px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
.title { text-align: center; font-weight: bold; }
.bottomStyle { font-weight: bold; }
</style>
